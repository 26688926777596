import React, { useState } from 'react'

import { useScopedIntl } from '../../../../hooks'
import { MfaMethod, settingsDisableMfa } from '../../../../requests'
import { DatacMessage, DatacModal } from '../../../common'

interface Props {
  onClose: () => void
  isOpened: boolean
  setMfaMethod: (value: MfaMethod) => void
}

export const SettingsDisableMfaModal: React.FC<Props> = ({ isOpened, onClose, setMfaMethod }) => {
  const intl = useScopedIntl('')
  const intlDisableMfa = useScopedIntl('auth.mfa.disable')
  const [isDisablingMfa, setIsDisablingMfa] = useState(false)

  const onSubmit = () => {
    setIsDisablingMfa(true)
    settingsDisableMfa({
      onSuccess: () => {
        setIsDisablingMfa(false)
        setMfaMethod(null)
        onClose()
      },
      onRequestError: code => DatacMessage.genericError(intl, code)
    })
  }

  return (
    <DatacModal
      className="study-settings-content__confirm-modal"
      title={intlDisableMfa('title')}
      isOpened={isOpened}
      onSubmit={onSubmit}
      onClose={onClose}
      loading={isDisablingMfa}
    >
      {intlDisableMfa('confirmation.message')}
    </DatacModal>
  )
}
