import React from 'react'

import { BasicLayout } from '../../../components/BasicLayout'
import { AuthRoute } from '../../../components/auth/AuthRoute'
import { SettingsPasswordChangeContent } from '../../../components/settings/SettingsPasswordChangeContent'

const SettingsPasswordChangePage = () => {
  return (
    <AuthRoute>
      <BasicLayout>
        <SettingsPasswordChangeContent />
      </BasicLayout>
    </AuthRoute>
  )
}

export default SettingsPasswordChangePage
export const Head = () => <title>Datacapt - Settings</title>
