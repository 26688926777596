import './SettingsEnableMfaModal.less'

import { Form } from 'antd'
import React, { useEffect, useState } from 'react'
import QRCode from 'react-qr-code'

import { useScopedIntl } from '../../../../hooks'
import { MfaMethod, settingsActivateMfa, settingsEnableMfa } from '../../../../requests'
import { DatacDigitsInput, DatacMessage, DatacModal } from '../../../common'

interface Props {
  onClose: () => void
  isOpened: boolean
  setMfaMethod: (value: MfaMethod) => void
}

export const SettingsEnableMfaModal: React.FC<Props> = ({ isOpened, onClose, setMfaMethod }) => {
  const intl = useScopedIntl('')
  const intlEnableMfa = useScopedIntl('auth.mfa.enable')
  const [formInstance] = Form.useForm()
  const [isEnablingMfa, setIsEnablingMfa] = useState(false)
  const [isWrongMfaCode, setIsWrongMfaCode] = useState(false)
  const [qrToken, setQrToken] = useState('')
  const [code, setCode] = useState('')

  const onRequestError = () => {
    DatacMessage.genericError(intl)
    setIsEnablingMfa(false)
  }

  useEffect(() => {
    if (isOpened) {
      settingsEnableMfa({
        onSuccess: setQrToken,
        onRequestError
      })
    }
  }, [isOpened])

  const onSubmit = () => {
    setIsEnablingMfa(true)
    setIsWrongMfaCode(false)
    settingsActivateMfa(
      { code },
      {
        onSuccess: () => {
          setIsEnablingMfa(false)
          setMfaMethod(MfaMethod.APP)
          onClose()
        },
        onWrongCode: () => {
          setIsWrongMfaCode(true)
          setIsEnablingMfa(false)
        },
        onRequestError
      }
    )
  }

  const setNewCode = (newCode: string) => {
    setCode(newCode)
    setIsWrongMfaCode(false)
  }

  return (
    <DatacModal
      isOpened={isOpened && !!qrToken}
      onClose={onClose}
      afterClose={() => formInstance.resetFields()}
      title={intlEnableMfa('title')}
      loading={isEnablingMfa}
      onSubmit={() => formInstance.submit()}
      destroyOnClose
    >
      <Form
        form={formInstance}
        name="settings-enable-mfa-form"
        className="settings-enable-mfa-modal__form"
        onFinish={onSubmit}
      >
        <QRCode value={qrToken} />
        <DatacDigitsInput
          name="passcode"
          label={intlEnableMfa('code.placeholder')}
          numberOfDigits={6}
          setValue={setNewCode}
          formInstance={formInstance}
          error={isWrongMfaCode ? intlEnableMfa('error.wrong_code') : undefined}
        />
      </Form>
    </DatacModal>
  )
}
